import React from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import './heading.scss';

const Heading = ({ children, size, level }) => {
    const HeadingElement = `h${level}`;
    const sizes = ['jumbo', 'large', 'medium', 'small', 'extra-small', 'tiny'];
    const headingClass = `heading heading_${
        !isUndefined(size) ? sizes[size] : sizes[level]
    }`;

    return <HeadingElement className={headingClass}>{children}</HeadingElement>;
};

Heading.propTypes = {
    children: PropTypes.node.isRequired,
    size: PropTypes.number,
    level: PropTypes.number.isRequired,
};

export default Heading;
