import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = (props) => {
    const {
        description = '',
        lang = 'en',
        meta = [],
        title,
        slug = '',
    } = props;
    const { site, socialImage } = useStaticQuery(
        graphql`
            query {
                site: site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
                socialImage: file(relativePath: { eq: "MA-1.jpg" }) {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        `
    );

    const image = `https://www.maddyandandrew.com${socialImage.childImageSharp.fluid.src}`;

    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s – ${site.siteMetadata.title}`}
            meta={[
                {
                  name: `robots`,
                  content: 'noindex, nofollow',
                },
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: `${title} – ${site.siteMetadata.title}`,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:site_name',
                    content: site.siteMetadata.title,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:url',
                    content: `https://www.maddyandandrew.com/${slug}`,
                },
                {
                    property: 'og:image',
                    content: image,
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: site.siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: `${title} – ${site.siteMetadata.title}`,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
                {
                    property: 'twitter:image',
                    content: image,
                },
            ].concat(meta)}
        />
    );
};

SEO.propTypes = {
    description: PropTypes.string,
    image: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    slug: PropTypes.string,
};

export default SEO;
