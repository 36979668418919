import * as React from 'react';
import Heading from '../components/heading';
import Text from '../components/text';
import Layout from '../components/layout';
import AnimateIn from '../components/animate-in';
import map from 'lodash/map';
import SEO from '../components/seo';
import './registry.scss';

const data = {
    title: 'Registry',
    body: (
        <>
            <b>
                Please know that celebrating with us on our wedding day is the
                greatest present you can give!
            </b>{' '}
            However, if you do wish to honor us with a gift, feel free to shop
            our registry below. There are plenty of Amazon options as well as
            items sourced from small businesses that we love to support. If you
            would rather donate to a charity in lieu of a gift, we have linked
            several causes that are near and dear to our hearts.
        </>
    ),
    registries: [
        {
            name: 'View Our Registry',
            url:
                'https://www.myregistry.com/wedding-registry/madison-duna-and-andrew-duna-/2817446/giftlist',
        },
    ],
    charities: [
        {
            name: 'Teach for America',
            description:
                'Maddy was placed in Tulsa by Teach For America, a program that places teachers in cities across the country in an effort to partner with low-income schools and better the educational outcomes for their students. She served as a dual language kindergarten teacher for two years before moving to Dallas. By donating to Teach For America, you are impacting students all across the country to access a high-quality education.',
            url: 'https://www.teachforamerica.org/support-us/donate',
        },
        {
            name: 'Big Brothers Big Sisters Lone Star Texas',
            description:
                'Big Brothers Big Sisters Lone Star Texas is a mentorship program that serves the North Texas area. It matches a youth, or a little, with a big, an adult, so that they can partner together in helping the little fulfill their potential. Maddy is currently a big and her dad, Todd, was a big for six years while in Dallas and still keeps in touch with his little, who will soon be graduating high school. Your donation is helping both children and adults thrive in our local community.',
            url:
                'https://4agc.com/donation_pages/b6d52c56-652f-4cd9-94e9-cd041b03e1df',
        },
    ],
};

const Registry = ({ registry }) => {
    return (
        <Heading level={2} size={3}>
            <a href={registry.url} target="_blank" rel="noopener noreferrer">
                {registry.name}
            </a>
            <Text size="medium" className="registry-pass">
                Registry Password: <i>DUNADUO2021</i>
            </Text>
        </Heading>
    );
};

const Charity = ({ charity }) => {
    return (
        <li>
            <Heading level={3} size={4}>
                {charity.name}
            </Heading>
            <Text size="medium">{charity.description}</Text>
            <Text size="medium">
                <a
                    href={charity.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="secondary-hover"
                >
                    Donate to {charity.name}
                </a>
            </Text>
        </li>
    );
};

// markup
const RegistryPage = () => {
    return (
        <Layout className="registry-page">
            <SEO title="Registry" slug="registry" />
            <AnimateIn onScroll>
                <Heading level={1}>{data.title}</Heading>
                <Text size="large">{data.body}</Text>
                <div className="registries">
                    {map(data.registries, (registry) => (
                        <Registry registry={registry} />
                    ))}
                </div>

                <Heading level={2}>Charities</Heading>
                <ul className="charities">
                    {map(data.charities, (charity) => (
                        <Charity charity={charity} />
                    ))}
                </ul>
            </AnimateIn>
        </Layout>
    );
};

export default RegistryPage;
